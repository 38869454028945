import { autoinject } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import {
  EasyfeedQuery,
  EasyfeedQueryVariables,
  EasyfeedQuery_easyfeed_weeks,
  EasyfeedQuery_easyfeed_weeks_batches,
  UpdateEasyfeedRecipeMutation,
  UpdateEasyfeedRecipeMutationVariables
} from '../../../../custom_typings/graphql';
import { getSessionUserId } from '../../../config/sessionService';
import { ensureDate, ensureNumber } from '../../../utility';
import { GraphQLBaseViewModel } from '../../common';
import './easyfeed.css';

@autoinject()
export class Easyfeed extends GraphQLBaseViewModel<
  void,
  EasyfeedQuery,
  EasyfeedQueryVariables
> {
  constructor(private router: Router) {
    super(getLogger('Easyfeed'));
  }
  siteId: number;
  activate(params: { id: string }) {
    const siteId = ensureNumber(params.id);
    this.siteId = siteId;
    this.variables = { siteId: siteId };
  }

  selectedWeek: EasyfeedQuery_easyfeed_weeks | undefined;
  updatedLoading: boolean = false

  dataChanged = (data: EasyfeedQuery | undefined) => {
    if (!data || this.selectedWeek) return;
    this.selectedWeek =
      data.easyfeed?.weeks?.find(
        w => w.weekNo == data.easyfeed?.currentWeek?.toString()
      ) || data.easyfeed?.weeks?.[0];
  };

  prevWeek() {
    if (this.selectedWeek && this.data) {
      const selectedWeek = this.data.easyfeed?.weeks?.find(
        w => w.weekNo === this.selectedWeek?.weekNo
      );
      if (selectedWeek) {
        const selectedWeekIndex = this.data.easyfeed?.weeks?.indexOf(
          selectedWeek
        );
        if (selectedWeekIndex !== undefined && selectedWeekIndex > 0) {
          this.selectedWeek = this.data.easyfeed?.weeks?.[selectedWeekIndex - 1];
        }
      }
    }
  }

  nextWeek() {
    if (this.selectedWeek && this.data && this.data.easyfeed) {
      const selectedWeek = this.data.easyfeed?.weeks?.find(
        w => w.weekNo === this.selectedWeek?.weekNo
      );
      if (selectedWeek) {
        const selectedWeekIndex = this.data.easyfeed?.weeks?.indexOf(
          selectedWeek
        );
        if (
          selectedWeekIndex !== undefined &&
          this.data.easyfeed.weeks !== null &&
          selectedWeekIndex + 1 < this.data.easyfeed.weeks.length
        ) {
          this.selectedWeek = this.data.easyfeed?.weeks?.[selectedWeekIndex + 1];
        }
      }
    }
  }

  goBack() {
    this.router.navigateBack();
  }

  checkboxChange(
    batch: EasyfeedQuery_easyfeed_weeks_batches,
    checked: boolean
  ) {
    this.updateEasyfeed(batch, !checked);
  }

  updateEasyfeed = async (
    batch: EasyfeedQuery_easyfeed_weeks_batches,
    checked: boolean
  ) => {
    this.updatedLoading = true;
    if (this.data && this.siteId && this.data.easyfeed) {
      const updateWeeks = this.data.easyfeed.weeks?.map(w =>
        w.weekNo === this.selectedWeek?.weekNo
          ? {
              ...w,
              batches: w.batches.map(b =>
                b.name === batch.name
                  ? {
                      tanks: b.tanks,
                      additional: b.additional,
                      name: b.name,
                      checked: checked,
                      checkedByUserId: checked ? getSessionUserId() : null,
                      checkedDate: checked
                        ? DateTime.fromJSDate(ensureDate(Date.now())).toString()
                        : null
                    }
                  : b
              )
            }
          : w
      );

      const variables: UpdateEasyfeedRecipeMutationVariables = {
        siteId: this.siteId.toString(),
        recipe: {
          ...this.data.easyfeed,
          weeks: updateWeeks
        }
      };
      await this.runQuery<UpdateEasyfeedRecipeMutation, UpdateEasyfeedRecipeMutationVariables>(
        gql`
          mutation UpdateEasyfeedRecipeMutation(
            $siteId: ID!
            $recipe: InputRecipe!
          ) {
            updateEasyfeedRecipe(siteId: $siteId, recipe: $recipe) {
              name
              currentWeek
              weeks {
                weekNo
                cropStage
                batches {
                  tanks {
                    name
                    products {
                      name
                      bags
                      color
                    }
                  }
                  additional
                  name
                  checked
                  checkedByUserId
                  checkedDate
                }
              }
            }
          }
        `,
        variables
      ).then((res: UpdateEasyfeedRecipeMutation) => {
        const updatedSelectedWeek = res.updateEasyfeedRecipe.weeks?.find(
          w => w.weekNo === this.selectedWeek?.weekNo
        );
        if (updatedSelectedWeek) {
          this.selectedWeek = updatedSelectedWeek;
        }
      });
      await this.revalidateAllActiveQueries();
      this.updatedLoading = false;
    }
  };

  query = gql`
    query EasyfeedQuery($siteId: Int!) {
      easyfeed(siteId: $siteId) {
        name
        currentWeek
        weeks {
          weekNo
          cropStage
          batches {
            tanks {
              name
              products {
                name
                bags
                color
              }
            }
            additional
            name
            checked
            checkedByUserId
            checkedDate
          }
        }
      }
    }
  `;
}
